exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cloud-js": () => import("./../../../src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-downloading-js": () => import("./../../../src/pages/downloading.js" /* webpackChunkName: "component---src-pages-downloading-js" */),
  "component---src-pages-downloading-msi-js": () => import("./../../../src/pages/downloading-msi.js" /* webpackChunkName: "component---src-pages-downloading-msi-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lost-key-js": () => import("./../../../src/pages/lost-key.js" /* webpackChunkName: "component---src-pages-lost-key-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-surveys-install-survey-js": () => import("./../../../src/pages/surveys/install-survey.js" /* webpackChunkName: "component---src-pages-surveys-install-survey-js" */),
  "component---src-pages-surveys-purchase-survey-js": () => import("./../../../src/pages/surveys/purchase-survey.js" /* webpackChunkName: "component---src-pages-surveys-purchase-survey-js" */),
  "component---src-pages-surveys-thank-you-js": () => import("./../../../src/pages/surveys/thank-you.js" /* webpackChunkName: "component---src-pages-surveys-thank-you-js" */),
  "component---src-pages-surveys-uninstall-survey-js": () => import("./../../../src/pages/surveys/uninstall-survey.js" /* webpackChunkName: "component---src-pages-surveys-uninstall-survey-js" */),
  "component---src-pages-upgrade-js": () => import("./../../../src/pages/upgrade.js" /* webpackChunkName: "component---src-pages-upgrade-js" */),
  "component---src-pages-volume-discount-js": () => import("./../../../src/pages/volume-discount.js" /* webpackChunkName: "component---src-pages-volume-discount-js" */),
  "component---src-pages-which-versions-for-my-key-js": () => import("./../../../src/pages/which-versions-for-my-key.js" /* webpackChunkName: "component---src-pages-which-versions-for-my-key-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-release-page-js": () => import("./../../../src/templates/release-page.js" /* webpackChunkName: "component---src-templates-release-page-js" */),
  "component---src-templates-support-page-js": () => import("./../../../src/templates/support-page.js" /* webpackChunkName: "component---src-templates-support-page-js" */)
}

