/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("fomantic-ui-css/semantic.min.css");
require("@fortawesome/fontawesome-free/css/fontawesome.min.css");
require("@fortawesome/fontawesome-free/css/brands.min.css");
require("@fortawesome/fontawesome-free/css/solid.min.css");

// require("prismjs/themes/prism-solarizedlight.css");
require("prismjs/themes/prism.css");
require("./src/index.css");


exports.onClientEntry = () => {};

